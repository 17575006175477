import "./users.scss";
import {setToken,getUserSetting} from "../../../utils/users";

class UsersController {
  constructor(
    $auth, ToastService, $translate, API, $anchorScroll, $window, $state, $location, DialogService,$rootScope) {
    'ngInject';

    this.$auth = $auth;
    this.$anchorScroll = $anchorScroll;
    this.$window = $window;
    this.ToastService = ToastService;
    this.$translate = $translate;
    this.API = API;
    this.users = {};
    this.$state = $state;
    this.$location = $location;
    this.video = undefined;
    this.DialogService = DialogService;
    this.$rootScope = $rootScope;
  }

  $onInit() {
    this.user_id = null;
    this.name = null;
    this.email = null;
    this.password = null;
    this.role_id = null;

    this.users = {};
    this.per_page = 10;
    this.filterArr = {};
    this.nameSearchInput = null;
    this.emailSearchInput = null;
    this.statusSearchInput = null;
    this.per_page = 10;
    this.selected_permissions = [];
    this.edit_selected_permissions = [];
    this.edited = [];
    this.locations = getUserSetting('locations');
    this.userLocation = [];
    this.userMainLocation = null;
    this.showPage = 'listRoles';

    this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;

    this.API.all("user")
      .customGET("permissions")
      .then((response) => {
        this.permissions = response.data.permissions;
      });

    this.API.all("user")
      .customGET("roles")
      .then((response) => {
        this.roles = response.data.roles_data_with_perm;
      });
  }

  onSelectTab(obj){
    this.$location.search(obj);
    this.video = undefined;
    if (obj.tab == 1) {
      this.video = 'usersPermissions';
    }
  }

  register() {

    let location =[]
    this.userLocation.forEach(id=>{
      location.push({locationId: id, main: id === this.userMainLocation})
    })
    let user = {
      name: this.name,
      email: this.email,
      password: this.password,
      role_id: this.role_id,
      location: location
    };

    this.$auth
      .signup(user)
      .then((response) => {
        this.ToastService.success(this.$translate.instant('USER_CREATED'));
        this.clearRegisterForm();
        this.getUser();
      })
      .catch(this.failedRegistration.bind(this));
  }

  clearRegisterForm() {
    this.user_id = null;
    this.name = null;
    this.email = null;
    this.password = null;
    this.role_id = null;
    this.userLocation = [];
    this.userMainLocation = null;
  }

  getUser(
    pageNumber = 1,
    filterArr = this.filterArr,
    per_page = this.per_page
  ) {
    var parent = this;

    this.per_page = per_page;

    let customGet = {
      per_page: per_page,
      page: pageNumber,
    };

    var reqObject = angular.extend(customGet, filterArr);

    reqObject.per_page = this.per_page;

    this.API.all("user")
      .customGET("list", reqObject)
      .then((response) => {
        parent.users = response.data.users;
        parent.current_user_id = response.data.currentUserId;
        parent.total = response.data.users.total;
        parent.current_page = response.data.users.current_page;
        parent.per_page = response.data.users.per_page;
        parent.last_page = response.data.users.last_page;
        parent.prev_page_url = response.data.users.prev_page_url;
        parent.next_page_url = response.data.users.next_page_url;

        parent.userPagesArray = response.data.userPagesArray;

        parent.prev_index = response.data.prevIndex;
        parent.next_index = response.data.nextIndex;
        parent.users.data.map(u => {
          let location = []

          if (u.location.length===0) {
            location.push('Tüm Depolar')
          } else {
            u.location.forEach(l => {
              location.push(l.location)
            })
          }
          u.locationText = location.join(', ')
        })
      });

    //this.per_page = per_page;
  }

  failedRegistration(response) {
    if (response.status === 422) {
      for (let error in response.data.errors) {
        return this.ToastService.error(response.data.errors[error][0]);
      }
    }
    this.ToastService.error(response.statusText);
  }

  filter() {
    let filter = {};

    if (this.nameSearchInput){
      filter['nameSearchInput'] = this.nameSearchInput;
    }

    if (this.emailSearchInput){
      filter['emailSearchInput'] = this.emailSearchInput;
    }

    if (this.statusSearchInput){
      filter['statusSearchInput'] = this.statusSearchInput;
    }

    this.getUser(1,filter,this.per_page)
  }

  userStatusChange(status, user_id) {
    let reqData = {
      status: status,
      user_id: user_id,
    };

    this.API.all("user/change-status")
      .post(reqData)
      .then((response) => {
        this.ToastService.success(response.data.msg);
      })
      .catch(this.statusChangeFailed.bind(this));
  }

  statusChangeFailed(response) {
    if (response.status === 422) {
      for (let error in response.data.errors) {
        return this.ToastService.error(response.data.errors[error][0]);
      }
    }

    if (response.status === 401) {
      for (let error in response.data.errors) {
        return this.ToastService.error(response.data.errors[error][0]);
      }
    }

    this.ToastService.error(response.statusText);
  }

  accordion(data) {
    this.create_form = data;
    localStorage.setItem("create_form", data);
  }

  closeEditForm() {
    this.edit_form = false;
    this.edited = false;
  }

  parseBoolean(data) {
    if (data == "false" || !data) {
      return false;
    } else if (data == "true" || data) {
      return true;
    }
  }

  saveRole() {
    var parent = this;
    this.selected_permissions_array = [];

    for (var key in this.selected_permissions) {
      if (this.selected_permissions.hasOwnProperty(key)) {
        if (this.selected_permissions[key]) {
          this.selected_permissions_array.push(key);
        }
      }
    }

    var reqData = {
      selected_permissions: this.selected_permissions_array,
      role_name: this.role_name,
      role_description: this.role_description,
    };

    this.API.all("user/save-role")
      .post(reqData)
      .then((response) => {
        parent.ToastService.success(this.$translate.instant('ROLE_CREATED'));
        parent.clearSaveRoleForm();
        parent.deselectAllPermissions();
        parent.roles = response.data.roles_data_with_perm;
        this.refreshToken()
      });
  }

  clearSaveRoleForm() {
    this.selected_permissions_array = null;
    this.role_name = null;
    this.role_description = null;
  }

  selectAllPermissions(mode = "add") {
    var i = 1;
    var parent = this;
    if (mode == "add") {
      this.selected_permissions = [];

      angular.forEach(this.permissions, function (value, key) {
        parent.selected_permissions[value.name] = true;
        i++;
      });
    } else {
      this.edit_selected_permissions = [];

      angular.forEach(this.permissions, function (value, key) {
        parent.edit_selected_permissions[value.name] = true;
        i++;
      });
    }
  }

  deselectAllPermissions() {
    this.selected_permissions = [];
    this.edit_selected_permissions = [];
  }

  editRole(roleId) {
    this.edit_form = true;
    this.showPage = 'editRole';
    this.edited = new Array();
    let reqObject = {
      role_id: roleId,
      token: this.$auth.getToken(),
    };
    this.edit_selected_permissions = [];

    var parent = this;
    //var body = $("html, body");
    this.edit_role_id = roleId;

    this.API.all("user")
      .customGET("get-role-by-id", reqObject)
      .then((response) => {
        var perm = response.data.roles_data_with_perm.perm;

        if (perm) {
          angular.forEach(perm, function (value, key) {
            parent.edit_selected_permissions[value.name] = true;
          });
        }

        parent.edit_role_name = response.data.roles_data_with_perm.role.name;
        parent.edit_role_description =
          response.data.roles_data_with_perm.role.description;

        //body.animate({scrollTop: 0}, "600", "swing");

        var role = response.data.roles_data_with_perm.role;
        parent.edited.push({[role.id]: true});
      });
  }

  updateRole(roleId) {
    this.edit_selected_permissions_array = [];

    for (var key in this.edit_selected_permissions) {
      if (this.edit_selected_permissions.hasOwnProperty(key)) {
        if (this.edit_selected_permissions[key]) {
          this.edit_selected_permissions_array.push(key);
        }
      }
    }

    let reqData = {
      role_id: roleId,
      role_name: this.edit_role_name,
      role_description: this.edit_role_description,
      perm: this.edit_selected_permissions_array,
      token: this.$auth.getToken(),
    };

    var parent = this;

    this.API.all("user/update-role")
      .post(reqData)
      .then((response) => {
        parent.ToastService.success(
          parent.edit_role_name + this.$translate.instant('ROLE_UPDATED')
        );
        parent.roles = response.data.roles_data_with_perm;
      });
    //
  }

  deleteRole(roleId) {
    let reqData = {
      role_id: roleId,
      token: this.$auth.getToken(),
    };

    var parent = this;

    this.API.all("user/delete-role")
      .post(reqData)
      .then((response) => {
        parent.roles = response.data.roles_data_with_perm;
      });
  }

  editUser(userId) {
    this.user_id = userId;
    let reqObject = {
      user_id: userId
    };

    this.API.all("user/get-by-id")
      .doPOST(reqObject)
      .then((response) => {
        this.name = response.data.userObj.name;
        this.email = response.data.userObj.email;
        this.user_id = response.data.userObj.id;
        if (response.data.roleObj != null) {
          this.role_id = response.data.roleObj.id;
        }
        this.userLocation = []
        response.data.userObj.location.forEach((location) => {
          this.userLocation.push(location.locationId)
          if (location.main){
            this.userMainLocation = location.locationId;
          }
        })
      });
  }

  impersonate(userId) {
    this.API.all("auth/impersonate/"+userId)
      .doGET()
      .then((response) => {
        this.$window.open(response.data.url, '_self');
      });
  }

  updateUser() {
    let location = [];
    this.userLocation.forEach(id=>{
      location.push({locationId: id, main: id === this.userMainLocation})
    })

    let reqObject = {
      user_id: this.user_id,
      name: this.name,
      email: this.email,
      role_id: this.role_id,
      password: this.password,
      location: location
    };
    var parent = this;

    this.API.all("user/update")
      .doPOST(reqObject)
      .then((response) => {
        parent.ToastService.success(
          parent.name + this.$translate.instant('USER_HAS_BEEN_UPDATED')
        );
        this.clearRegisterForm();
      });
  }

  closeUserEditForm() {
    this.edit_user_form = false;
  }

  openIntroductionVideoModal(video) {
    openIntroductionVideo(video);
  }

  clearUserSearchForm() {
    this.nameSearchInput = null;
    this.emailSearchInput = null;
    this.statusSearchInput = null;
  }

  showScreenDetail(imgName) {
    var options = { locals: {reqData: {window_name: imgName,ss_name: this.slugify(imgName)}}, controller: "ShowScreenForPermissionController" };
    var screenDialogPromise = this.DialogService.fromTemplate(
        "showScreenForPermission",
        options
    );
    var parent = this;

    screenDialogPromise.then(
        function () {
        },
        function () {

        }
    );
  }

  slugify(text) {
    var trMap = {
      'çÇ':'c',
      'ğĞ':'g',
      'şŞ':'s',
      'üÜ':'u',
      'ıİ':'i',
      'öÖ':'o'
    };
    for(var key in trMap) {
      text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
    }
    return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
        .replace(/\s/gi, "-") // convert spaces to dashes
        .replace(/[-]+/gi, "-") // trim repeated dashes
        .toLowerCase();
  }

  refreshToken() {
    this.API.all("auth/token")
        .doGET()
        .then((response) => {
          if (response.data.token) setToken(response.data.token)
          else this.ToastService.showHtml('warn',this.$translate.instant('TOKEN_NOT_RENEWED'))
        });
  }

  selectAllOptionsByModel(model) {
    if (model === 'userLocation'){
      this.userLocation = this.locations.map(d=>d.id)
    }
  }
  deselectAllOptionsByModel(model) {
    this[model] = [];
  }

  logoutAllDevice(userId){
    this.$rootScope.$broadcast('app-root','logout',{allDevice:true,userId:userId});

  }

}

UsersController.$inject = ["$auth", "ToastService", "$translate", "API", "$anchorScroll", "$window", "$state", "$location", "DialogService","$rootScope"];

export const UsersComponent = {
  templateUrl: './views/angular/app/components/users/users.component.html',
  controller: UsersController,
  controllerAs: 'vm',
  bindings: {}
};
