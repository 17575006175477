import {getUserInfo} from "../../../utils/users";
import moment from "moment/moment";
import "./receiving.scss";

class ReceivingController {
    constructor(API, ToastService, $translate, $mdBottomSheet, $scope, DialogService, $state, $location, $anchorScroll, $filter) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        var translate = $filter('translate');

        this.falseBeep = new Audio('./files/error.wav');
        this.trueBeep = new Audio('./files/success.wav');

        this.userInfo = getUserInfo();
        this.currentUserId = this.userInfo.uid;
        this.userSettings = this.userInfo.settings;

        this.screen = 'list';
        this.processList = [];
        this.activeProduct = null
        this.activeProductIndex = 0;
        this.productBarcode = ''
        this.productQuantity = 0
        this.lotProductQuantity = []
        this.quantityType = 'add';
        this.lotQuantityType = [];
        this.quantity = 1;
        this.receivingType = null;
        this.serviceType = null;
        this.serviceTypes = [
            {id: 'purchase', name: translate('PURCHASE_RECEIVING')},
            {id: '10', name: translate('PRODUCTION_RECEIVING')},
            {id: '22', name: translate('LOCATION_TRANSFER_ENTRY_RECEIVING')},
            {id: '17', name: translate('FREE_ENTRY_RECEIVING')},
            {id: 'return_outside', name: translate('RETURN_RECEIVING')},
        ];
        this.lotEntryMode = false
        this.rejectedQuantityCheck = false
        this.sortModeStr = "arrow_drop_up";

        this.showAllApproveMessage = true
        //this.receivingType;
    }

    $onInit() {
        let parent = this;
        this.$scope.$on('receivingTrigger', function (event, data) {
            parent.getReceivingDetail(data)
        })

        parent.clearFilter()
        parent.getReceivingList()

        this.API.all("location")
          .customGET()
          .then((response) => {
              if (response.errors === false) {
                  this.locations = response.data.location;
              }
          });
        this.$scope.$on('selectProductEvent', function (event, selectProduct) {
            if (parent.selectedTabIndex == parent.wrapperTabIndex && parent.screen == 'detail') {
                parent.addReceivingItem({
                    receivingType: parent.receivingDetail.receivingType,
                    serviceId: parent.receivingDetail.serviceId,
                    productId: selectProduct.id,
                    quantity: 1
                });
            }
        });
    }

    getReceivingList() {
        this.receivingDetail = null;
        this.activeProduct = null;
        this.activeProductIndex = 0;
        this.screen = 'list';

        if (this.serviceType == 'purchase') {
            this.receivingType = 'offer';
        }else{
            this.receivingType = 'action';
        }
        this.receivingType = null;

        this.API.all("receiving")
            .customGET(null, {
                receivingType: this.receivingType,
                serviceType: this.serviceType,
                transactionId: this.filter.transactionId,
                customerCode: this.filter.customerCode,
                customerName: this.filter.customer,
                locationId: this.filter.location
            })
            .then((response) => {
                if (204 === response.response.status) {
                    this.processList = [];
                    return;
                }
                this.showAllApproveMessage = true
                this.processList = response.data.list
            })
    }

    getReceivingDetail(receivingType, serviceId) {
        return this.API.all(`receiving/${receivingType}`)
            .customGET(null, {serviceId: serviceId})
            .then((response) => {
                this.prepareDetailData(response.data.list)
            })
    }


    productCheck(receivingType, serviceId, barcode) {
        this.API.all(`receiving/${receivingType}/product-check`)
            .customGET(null, {
                serviceId: serviceId,
                barcode: barcode,
            })
            .then((response) => {
                const {list} = response.data;
                this.showAllApproveMessage = false;
                if (list?.receivingExists) {
                    this.activeProductIndex = this.receivingDetail.items.findIndex(r => r.receivingId === list.receivingId)
                    this.prepareDetailData(this.receivingDetail)

                    if (list.status === 'conflict' || list.status === 'approve') {
                        this.ToastService.showHtml('success', this.$translate.instant('PRODUCT_CHANGED'))
                        this.clearData()
                        return;
                    }

                    if (this.activeProduct.status === 'done') {
                        this.ToastService.showHtml('success', this.$translate.instant('NOT_RECEIVING'))
                        this.clearData()
                        this.trueBeep.play();
                        return;
                    }
                    if (this.activeProduct.tracking) {
                        this.ToastService.showHtml('success', this.$translate.instant('WAS_BBD'))
                        this.clearData()
                        this.trueBeep.play();
                        return;
                    }

                    this.addReceivingItemQuantity({
                        serviceId: serviceId,
                        receivingId: this.activeProduct.receivingId,
                        quantity: 1
                    })
                    this.clearData()
                    this.trueBeep.play();
                    return;
                }
                if (!list.canAddProduct) {
                    this.ToastService.showHtml('success', this.$translate.instant('ACTIVE_RECEIVING_NOT_SUPPORT_ADDING_PRODUCTS'))
                    this.clearData();
                    return;
                }

                if (list.canAddProduct) {
                    if (list.productExists) {

                        this.searchProduct(list.productBarcode, list.productId)
                        this.clearData()
                        this.trueBeep.play();
                        return;
                    }

                    this.DialogService.confirm(
                        this.$translate.instant('CONFIRM_MESSAGE'),
                        this.$translate.instant('PRODUCT_NOT_FOUND_SYSTEM')
                    ).then(() => {
                        this.searchProduct(list.productBarcode, list.productId, true)
                        return;
                    })

                }
                this.productBarcode = null
                this.productQuantity = 0;
                this.trueBeep.play();
            }).catch(response => {
            if (response.status === 422) {
                this.falseBeep.play()

            }
        })
    }

    addReceivingItemQuantity({receivingId, quantity, serialNumber, expireDate, note}) {
        let type = this.rejectedQuantityCheck ? 'reject' : 'accept'
        return this.API.all(`receiving/${receivingId}`)
            .customPUT({
                quantity: quantity,
                serialNumber: serialNumber,
                expireDate: expireDate,
                type: type,
                documents: this.activeProduct?.gallery?.map(g => {
                    return g.uploadUrl
                }),
                note: note,
            })
            .then((response) => {
                this.prepareDetailData(response.data.list)
            })
    }

    updateReceivingItemQuantity({
                                    receivingId,
                                    quantity,
                                    receivingItemId,
                                    note,
                                    receivingDetailId,
                                }) {

        this.confirmMessage('updateQuantity', this.activeProduct.receivingDetail).then(ok => {
            if (!ok) {
                return;
            }
            let type = this.rejectedQuantityCheck ? 'reject' : 'accept'

            this.API.all(`receiving/${receivingId}/quantity`)
                .patch(null, {
                    receivingItemId: receivingItemId,
                    quantity: quantity,
                    type: type,
                    note: note,
                    receivingDetailId: receivingDetailId
                })
                .then((response) => {
                    this.prepareDetailData(response.data.list)
                })
        })
    }

    updateReceivingItemLot({
                               receivingId,
                               receivingItemId,
                               quantity,
                               note,
                               receivingDetailId,
                               serialNumber,
                               expireDate,
                           }) {

        this.API.all(`receiving/${receivingId}/lot`)
            .patch(null, {
                receivingItemId: receivingItemId,
                quantity: quantity,
                note: note,
                receivingDetailId: receivingDetailId,
                serialNumber: serialNumber,
                expireDate: expireDate,
            })
            .then((response) => {
                this.prepareDetailData(response.data.list)
            })
    }

    confirmMessage(type, data) {
        let parent = this;
        return this.DialogService.fromTemplateV2('./views/angular/app/components/receiving/partials/dialog/confirm.html', {
            onShowing: function (scope, element, options, controller) {
                scope.type = type;
                scope.data = data;
                scope.DialogService = parent.DialogService;

                scope.done = function (ok) {
                    scope.DialogService.$mdDialog.hide(ok)
                }
            }
        }).catch(err => {
            console.log(err)
        });
    }

    setSerialNumber({serialNumber, expireDate, quantity, receivingId}) {
        let type = this.rejectedQuantityCheck ? 'reject' : 'accept'
        if (!serialNumber && !expireDate) {
            this.ToastService.showHtml('warn', this.$translate.instant('ENTER_SERIAL_OR_EXPIRATION_DATE'))
            return;
        }
        this.addReceivingItemQuantity({
            receivingId: receivingId,
            serialNumber: serialNumber,
            expireDate: expireDate,
            quantity: quantity,
            type: type
        })
        this.serialNumber = null;
        this.expireDate = null;
        this.quantity = 1;
    }




    openMenu($mdMenu, ev) {
        this.originatorEv = ev;
        $mdMenu.open(ev);
    };

    clearFilter() {
        this.filter = {
            limit: 20, page: 1,
        };
        this.receivingDetail = null;
        this.activeProduct = null;
        this.activeProductIndex = 0;
        this.screen = 'list';
        this.processList = []
        this.productBarcode = ''
        this.productQuantity = 0
    }

    clearSearchFilter() {
        this.filter.customer = null;
        this.filter.customerCode = null;
        this.filter.transactionId = null;
        this.filter.limit = null;
        this.filter.page = null;
    }

    showCameraView() {
        var parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-camera/bottom-camera.component.html",
            controller: "BottomCameraController",
            controllerAs: "vm",
            bindToController: true,
            locals: {}
        }).then(response => {
            parent.productBarcode = response;
        })
            .catch(function (error) {
                console.error(error, "<<<<errorr<<<<<<<"); // Hata durumunda konsola bas
            });
    }


    showItemRecipe(productName, items) {
        let d = this.DialogService;
        let options = {
            controllerAs: 'vm',
            bindToController: true,
            clickOutsideToClose: true,
            escapeToClose: true,
            onShowing: function (scope, element, options, controller) {
                scope.productName = productName;
                scope.items = items;
                scope.DialogService = d;
                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            },
        }

        this.DialogService.fromTemplateV2(
          './views/angular/app/components/offer/partials/dialog/recipe-item.html',
          options
        )
    }

    showProductBottomSheet(productId) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                id: productId
            }
        })
    }

    getUploadModal() {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/upload/upload.component.html",
            controller: "UploadController",
            controllerAs: "vm",
            bindToController: true,
        }).then(gallery => {
            if (!gallery) return;
            let uploadItemCount = 0;
            let uploadedItemCount = gallery.length;

            gallery.map((item) => {
                this.API.all("document/upload")
                    .customGET(null, {
                        fileName: item?.filename, size: item?.size, type: item?.type,
                    })
                    .then((response) => {
                        item.uploadUrl = response.data.list;
                        this.saveGallery(item.uploadUrl.uri, item.file).then(resp => {
                            ++uploadItemCount
                            item.upload = true
                            if (!this.activeProduct?.gallery) {
                                this.activeProduct.gallery = []
                            }
                            this.activeProduct?.gallery.push(item)
                        }).catch(status => {
                            ++uploadItemCount
                            item.upload = false
                            this.activeProduct?.gallery.push(item)
                            this.ToastService.showHtml('warn', this.$translate.instant('MISTAKE_IMAGE_UPLOAD') + status)
                        }).finally(() => {
                            this.activeProduct.galleryUpladWait = uploadItemCount !== uploadedItemCount
                        })
                    })
            })
        })
    }

    saveGallery(uri, file) {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open('PUT', uri, true);
            //  xhr.setRequestHeader('Content-Type', file.type);

            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(true)
                    console.log('File uploaded successfully!');
                } else {
                    reject(xhr.statusText)
                    console.error('Error uploading file: ', xhr.statusText);
                }
            };

            xhr.onerror = function () {
                reject(xhr.statusText)
                console.error('Error uploading file: ', xhr.statusText);
            };

            xhr.send(file);
        })
    }

    showGallery(gallery) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/upload/gallery.component.html",
            controller: "GalleryController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                gallery: gallery
            }
        })
    }

    prepareDetailData(data) {
        this.screen = 'detail'
        this.receivingDetail = data

        this.activeProduct = this.receivingDetail.items[this.activeProductIndex]
        this.activeProduct.prepare = this.activeProduct.status !== 'conflict' && this.activeProduct.status !== 'approve'

        this.activeProduct?.receivingDetail.detail?.map(d => {
            d.newQuantity = d.quantity
            d.newLotSerialNumber = d.lotSerialNumber
            d.newLotExpireDate = d.expireDate
            return d;
        })

        this.activeProduct.lotEntryMode = this.activeProduct.tracking
        this.clearData()
    }
    changeReceiving() {
        this.processList.reverse();
        if (this.sortModeStr === "arrow_drop_up"){
            this.sortModeStr = "arrow_drop_down"
        } else if (this.sortModeStr === "arrow_drop_down"){
            this.sortModeStr = "arrow_drop_up"
        }
    }
    clearData() {
        this.productQuantity = 0
        this.productNote = null
        this.quantityType = 'add'
        this.productBarcode = null
    }


    searchProduct(term, productId, newProduct = false) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                term: term,
                id: productId,
            },
            onShowing: function (scope, element, options, controller) {
                if (newProduct) {
                    controller.quickCreateStock()
                }
            },
        })
    }

    addReceivingItem({receivingType, serviceId, barcode, productId, quantity}) {
        this.API.all(`receiving/${receivingType}`)
            .customPOST({
                serviceId: serviceId,
                barcode: barcode,
                productId: productId,
                quantity: quantity
            })
            .then((response) => {
                if (response.data.list.selectedItemId){
                    this.activeProductIndex = response.data.list.items.findIndex(d =>d.receivingId === response.data?.list?.selectedItemId)
                }
                this.prepareDetailData(response.data.list)
            }).catch(response => {
            if (response.status === 422) {
                this.falseBeep.play()

            }
        })
    }

    approve(receivingId) {
        return this.API.all(`receiving/${receivingId}/approve`)
            .patch()
            .then((response) => {
                this.prepareDetailData(response.data.list)
                this.showAllApproveMessage = this.receivingDetail.itemCount === this.receivingDetail.status.approve.count

            })
    }
    done(receivingType,serviceId) {
        console.log(receivingType,serviceId)
        return this.API.all(`receiving/${receivingType}/done`)
            .customPUT({
                serviceId: serviceId,
            })
            .then((response) => {
                this.getReceivingList()
            })
    }
    resolve(receivingId){
        return this.API.all(`receiving/${receivingId}/resolve`)
            .patch()
            .then((response) => {
                this.prepareDetailData(response.data.list)
            })
    }
    reverse(receivingId,type){
        return this.API.all(`receiving/${receivingId}/reverse`)
            .patch({
                reverseType : type
            })
            .then((response) => {
                this.prepareDetailData(response.data.list)
            })
    }

    selectProduct(receivingId){
        this.activeProductIndex = this.receivingDetail.items.findIndex(r => r.receivingId === receivingId)
        this.prepareDetailData(this.receivingDetail)
        this.$location.hash('top');
        this.$anchorScroll();
    }
}

ReceivingController.$inject = ["API", "ToastService", "$translate", "$mdBottomSheet", "$scope", "DialogService", "$state", "$location", "$anchorScroll", "$filter"];
const ReceivingComponent = {
    templateUrl: "./views/angular/app/components/receiving/partials/receiving.component.html",
    controller: ReceivingController,
    controllerAs: "vm",
    bindings: {
        receivingType: '<',
        serviceType: '<',
        selectedTabIndex: '<',
        wrapperTabIndex: '@',
    },
};

export {ReceivingComponent, ReceivingController}
